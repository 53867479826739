import React, { useCallback, useContext, useState } from 'react'
import { authcontext } from '../contexte/auth/Authcontext'
import getsemaine from '../function/getsemaine'
import SemaineNumero from './SemaineNumero'
import Loading from './Loading'
import Alert from './Alert';
import { mesageContext } from '../contexte/mesage/MesageContext'
import Message from '../class/Message'

export default function AddRepas({recette,closeModal=()=>{}}) {
    const {api,user,authFetch} = useContext(authcontext)
    const [numberOfPersonne,setNumberOfPersonne] = useState(user.preference.nbPers)
    const [selectWeek,setSelectWeek] = useState({week:getsemaine(new Date()),year:new Date().getFullYear()})
    const [message,setMessage] = useState({mesage:false,type:false})
    const {addMessage} = useContext(mesageContext)
    

     const postRepas =  useCallback(()=>{
        const data = {
            repas:{idRecette:recette.id,numberOfPresonne:numberOfPersonne},
            week:selectWeek.week,
            year:selectWeek.year
        }
        authFetch.postJson(`/menu/repas`,data).then(()=>{
            closeModal()
            addMessage(new Message("repas bien ajouter"))
        }).catch((err)=>{
            console.error(err)
            addMessage(new Message('Un problemme est survenue'))
        })
     },[api,setMessage,selectWeek,numberOfPersonne,recette])

    if(recette !== false){
        return (
            
                <div className='contenair contenair--centre group-form'>
                    <div className='contenair contenair--centre group-form__group'>
                        <Alert mesage={message}/>
                    </div>
                    <div className='group-form__titel'>{recette.name}</div>
                    <div className='contenair contenair--scroll semaine group-form__group '>
                            {[...new Array(52).fill({week:getsemaine(new Date()),year:new Date().getFullYear()})].map((curentWeek,index)=>{
                                if(curentWeek.week + index <= 52){
                                    const week = {week:curentWeek.week + index,year:curentWeek.year}
                                    return <SemaineNumero key={week.week+'/'+week.year} set={setSelectWeek} semaine={week} select={selectWeek}/>
                                }else{
                                    const week = {week:(curentWeek.week + index) - 52,year:curentWeek.year+1}
                                    return <SemaineNumero key={week.week+'/'+week.year} set={setSelectWeek} semaine={week} select={selectWeek}/>
                                }
                            })}
                    </div>
                    <div className='contenair contenair--centre group-form__group'>
                        <label htmlFor="nbPers">Nombre de personne</label>
                        <input type="number" id='nbPers' value={numberOfPersonne} onChange={(e)=>{setNumberOfPersonne(e.target.value)}}/>
                    </div>
                    <div className='btn btn--big btn--green group-form__btn' onClick={()=>{postRepas()}}>Ajouter le repas</div>
                </div>
            
        )
    }
    return <Loading/>
}
