import React, { useCallback, useContext, useEffect } from 'react'
import useFetchAuth from '../../hook/useFetchAuth'
import { useState } from 'react'
import Loading from '../../composants/Loading'
import useFetchRecettesById from '../../hook/useFetchRecettesById'
import { authcontext } from '../../contexte/auth/Authcontext'

import RecetteMenu from '../../composants/recettes/RecetteMenu'
import Modal from '../../composants/Modal'
import AddRepas from '../../composants/AddRepas'

export default function Favorie() {

    const {api,fav,setFav} = useContext(authcontext)
    console.log(Array.from(fav.values()))
    const fetchAuth = useFetchAuth()
    
    const [recettes,setRecettes] = useState(false)
    const [activeModal,setActivModal] = useState(false)
    const [recette,setRecette] = useState(false)

    useEffect(()=>{
        setRecettes(Array.from(fav.values()))
    },[fav,setRecettes])
    

    const add = useCallback((id)=>{
        console.log("add")
        const index = recettes.findIndex((recette)=>(recette.id === id))
        const recetteAdd = recettes[index]
        console.log(recetteAdd)
        setRecette(recetteAdd)
        setActivModal((prev)=>(!prev)) 
    },[setActivModal,recettes,setRecette])

    const favToggle  = useCallback((id)=>{
        fetchAuth(api+'/recette/favori','patchJson',{idRecette:id}).then((recette)=>{
            setFav((prev)=>{
                if(prev.has(id)){
                    prev.delete(id)
                }else{
                    prev.set(id,recette)
                }
                return new Map(prev)
            })
        })
    },[]) 
    console.log(activeModal)
    if(recettes === false) return <Loading/>
    if(recettes.length === 0) return <div><h1>Aucun favorie</h1></div>
  return (
    <div className='contenair warpper'>
         {activeModal ? <Modal className='--centre' toggleModal={()=>{setActivModal(false)}}><AddRepas recette={recette} closeModal={()=>{setActivModal(false)}}/></Modal> :null}
        <div className='contenair  warpper'>
                <div className='contenair contenair--column contenair--column--centre  warpper'>
                    <div className='contenair contenair--scroll contenair--centre content__recettes'>
                        {recettes.map((recette)=>(<RecetteMenu actionActiv={{active:true,add:add,favorie:favToggle}} key={recette.id} recette={recette}/>))}
                        
                    </div>
                </div>
        </div>
    </div>
  )
}
