import React, { useContext, useEffect, useState } from 'react'
import Field from './Field'
import Mesage from '../MessageUi'
import { NavLink } from 'react-router-dom'
import  { mesageContext } from '../../contexte/mesage/MesageContext.jsx'
import Message from '../../class/Message.js'

export default function GroupForm({titel,fields = [],fieldsModify=false,save,valid,input=false,btnText='Enregistrer',options=false}) {
    const [modify,setModify] = useState(input)
    const [data,setData] = useState(fields.reduce((prev,curent)=>({...prev,[curent.name]:curent.value}),{}))
    const [errs,setErrs] = useState({err:false})
    const {addMessage} = useContext(mesageContext)

    useEffect(()=>{
        valid(data).catch((err)=>{
            setErrs((prevErrs)=>({...prevErrs,err:true}))    
        })
    },[modify])

    const onChange = (e)=>{
        setData((prevData)=>({...prevData,[e.target.name]:e.target.value}))
        setErrs((prevErrs)=>({...prevErrs,[e.target.name]:''}))
        valid({...data,[e.target.name]:e.target.value}).then(()=>{
            setErrs({err:false})
        }).catch((errors)=>{
            setErrs((prevErrs)=>({...prevErrs,err:true}))
            for(const err of errors){
                setErrs((prevErrs)=>({...prevErrs,[err.for]:err.msg}) )
                
            }
        })
    }

    const trySave = ()=>{
        if(!errs.err){
            save(data).then((message)=>{

                setModify(input ? input:false)
                addMessage(new Message(message),'ok',5000)
               
            }).catch((err)=>{
                addMessage(new Message(err.message),'nok',5000)
            })
        }

    }

    return (
    <div className='contenair group-form'>
        
        {input ? null:
            <span className='group-form__border__wapper'>
            <span className='group-form__border--up'>
                <i onClick={()=>{setModify(!modify)}} className='material-symbols-outlined'>edit</i>
            </span>
            <span className='group-form__border--left group-form__border--right group-form__border--bottum'></span>
        </span>
        }
        <h3 className='titel'>{titel}</h3>
        {modify ? 
            fieldsModify === false ? 
                fields.map((field,index)=>(<Field err={errs[field.name]} key={`${titel}Filed/edit1/${index}`} field={field} input={{value:data[field.name],onChange:onChange}} className='field--edit'/>)):
                fieldsModify.map((field,index)=>(<Field err={errs[field.name]} field={field} key={`${titel}Filed/edit2/${index}`} input={{value:data[field.name],onChange:onChange}} className='field--edit'/>)):
            fields.map((field,index)=>(<Field key={`${titel}Filed/${index}`} field={field}/>))}
        {modify ? <button disabled={errs.err} onClick={trySave} className='btn btn--big'>{btnText}</button>:null}
        {options? <div className='contenair contenair--space-evently group-form__options'> {options.map((option,index)=>(<NavLink key={`${titel}/option/${index}`} to={option.ref} replace={true} className={'group-form__options__option'} >{option.name}</NavLink>))} </div> : null}

    </div>
  )
}
