class Message{
    /**
     * 
     * @param {String} message 
     * @param {String} type 
     * @param {Number} time 
     */
    constructor(message,type='info',time=4000){
        this.message = message
        this.type = type
        this.time = time
    }
}
module.exports = Message