import React from 'react';
import { useContext } from 'react';
import { authcontext } from '../contexte/auth/Authcontext';
import { useEffect } from 'react';
import { useState } from 'react';
import getsemaine from '../function/getsemaine';
import Loading from './Loading';
import useFetchAuth from '../hook/useFetchAuth';
import MenuSemaine from './MenuSemaine';
import getYear from '../function/getYear';

const MenuSemaineHome = () => {
    const {api,authFetch} = useContext(authcontext)
    const [load,setLoad] = useState(false)
    const [menus,setMenus] = useState(false)

    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        const now = new Date()
        const year = new Date().getFullYear()
        /* Promise.all([
            fetchAuth(api+`/menu/${getsemaine(now)}-${getYear(now)}`,'get'),
            fetchAuth(api+`/menu/${getsemaine(now,1)}-${getYear(now,1)}`,'get'),
            fetchAuth(api+`/menu/${getsemaine(now,2)}-${getYear(now,2)}`,'get'),
            fetchAuth(api+`/menu/${getsemaine(now,3)}-${getYear(now,3)}`,'get')
        ]) */
        Promise.all([
            authFetch.get(`/menu/${getsemaine(now)}-${getYear(now)}`),
            authFetch.get(`/menu/${getsemaine(now,1)}-${getYear(now,1)}`),
            authFetch.get(`/menu/${getsemaine(now,2)}-${getYear(now,2)}`),
            authFetch.get(`/menu/${getsemaine(now,3)}-${getYear(now,3)}`)
        ])
        .then((menus)=>{
            console.log(menus)
            setMenus(menus)
            setLoad(true)
        }).catch((err)=>{
            console.error(err)
        })

    },[api])
    if(load){

        return (
            <div className='contenair contenair--column  menu__home contenair--scroll'>
                {menus.map((menu)=>(<MenuSemaine key={menu.id} menu={menu}/>))}
                {/* {menu.repas.map((repas,index)=>(<CardRecetteRepas key={'repas'+ repas.idMenu+'/'+ index} repas={repas.recette}/>))} */}
            
            </div>
        );
    }

    return <Loading/>
};

export default MenuSemaineHome;