import React, { useContext, useEffect, useState } from 'react';

import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';


import { authcontext } from './Authcontext';
import Header from '../../composants/Header/Header';
import useFetchAuth from '../../hook/useFetchAuth';
import Loading from '../../composants/Loading';

const Private = () => {
    const {user,setUser,api,fav,setFav,authFetch} = useContext(authcontext)
    const url = useLocation()
    const nav = useNavigate()
    
    const [loading,setLoading] = useState(true)

    const fetchAuth = useFetchAuth()
    
    useEffect(()=>{
      if(user === false){
        return setLoading(false)
      }

      if(authFetch !== null){
        authFetch.get('/user/preference').then((preference)=>{
          setUser((prevUser)=>({...prevUser,preference}))
          setLoading(false)
        }).catch((err)=>{
          if(err === 404 ){
            setLoading(false)
            if(url.pathname !== "/preference") return nav('/preference')
          }
        })
  
        authFetch.get('/recette/fav/rec').then((favs)=>{
          const favorie = new Map(favs.map((fav)=>([fav.id,fav])))
          setFav(favorie)
        }).catch((err)=>{
          if(err === 404){
            setFav(new Map())
          } 
          console.error(err)
        })
      }
      

    },[api,setUser,authFetch])
     
    if(loading && authFetch !== null) return <Loading/>

    if(!user){
        
        return <Navigate to='/login' replace={true} state={{from:url}}/>
    }
    console.log(fav)
    if(fav === false) return <Loading/>

    if(url.pathname === '/' || url.pathname === ''){
      return <Navigate to='/home' replace={true}/>
    }


    return (
      <div className='contenair contenair--column warpper'>
        <div className='contenair contenair--column  warpper__header'>
          
          <Header/>      
        </div>
        <div className='contenair warpper__body'>
          <Outlet></Outlet>
        </div>
      </div>
    );
};

export default Private;