import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useFetchRecettes from '../hook/useFetchRecettes'
import Loading from '../composants/Loading'
import SearchBar from '../composants/SearchBar'
import useDebounce from '../hook/useDebounce'
import Pages from '../composants/Pages'
import RecetteMenu from '../composants/recettes/RecetteMenu'
import Modal from '../composants/Modal'
import AddRepas from '../composants/AddRepas'
import useFetchAuth from '../hook/useFetchAuth'
import { authcontext } from '../contexte/auth/Authcontext'
import { useParams, useSearchParams } from 'react-router-dom'

export default function Recettes() {

    const fetchAuth = useFetchAuth()
    const {api,fav,setFav,authFetch} = useContext(authcontext)
    
    const [showFitre,setShowFilter] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [showUnactive,setShowUnactive] = useState(searchParams.has("showUnactive"))
    const [orderByName,setOrderByName] = useState(searchParams.has("orderByName"))
    const [page,setPage] = useState(parseInt(searchParams.has("pageAsk")? parseInt(searchParams.get("pageAsk")) :1))
    const [text,setText] = useState(searchParams.has("search") ? searchParams.get("search"):"")
    const [activeModal,setActivModal] = useState(false)
    const debouncedText = useDebounce(text,400) 
    const recettes = useFetchRecettes({limit:10,page:page,text:debouncedText,showUnactive:showUnactive,orderByName:orderByName})
    const [recette,setRecette] = useState(false)
    const firstTime = useRef(true)

    useEffect(()=>{
        if(firstTime.current){
            firstTime.current = false
        }else{
            const url = new URL(window.location.href.split('?')[0])
            url.searchParams.append("pageAsk",page.toString())
            if(debouncedText!="") url.searchParams.append("search",debouncedText)
            if(showUnactive) url.searchParams.append("showUnactive",true)
            if(orderByName) url.searchParams.append("orderByName",true)
            console.log(url)
            window.history.replaceState({},null,url.href)
        }
    },[debouncedText,page,showUnactive,orderByName])
    
    const handelText  = useCallback((e)=>{
        const value = e.target.value
        setText(value)
         setPage(1)
    },[setText])
    
    /* const search = useCallback(()=>{
        if(text !==''){
            setMode('text')
        }else{
            setMode('all')
        }
    },[setMode,text]) */
    
    function ToggleshowFilter(){
        setShowFilter((current)=>(!current))
    }

    const add = useCallback((id)=>{
        const index = recettes.recettes.findIndex((recette)=>(recette.id === id))
        const recetteAdd = recettes.recettes[index]
        console.log(recetteAdd)
        setRecette(recetteAdd)
       setActivModal(true) 
    },[setActivModal,recettes,setRecette])

    const favToggle  = useCallback((id)=>{
        authFetch.patchJson('/recette/favori',{idRecette:id}).then((recette)=>{
            setFav((prev)=>{
                if(prev.has(id)){
                    prev.delete(id)
                }else{
                    prev.set(id,recette)
                }
                return new Map(prev)
            })
        })
    },[]) 

    /* if(recettes === false){
        return(
            <div className='contenair contenair--centre'>
                <SearchBar text={text} setText={handelText}/>
                <Loading/>
            </div>
        )
    }
    if(recettes === 404){
        return(
            <div className='contenair contenair--centre'>
                <SearchBar  text={text} setText={handelText}/>
                <div className='contenair'>
                    <h1>
                        Aucune recette trouver
                    </h1>
                </div>
            </div>
        )
    } */
  return (
      <div className='contenair contenair--column warpper'>
         {activeModal ? <Modal className='--centre' toggleModal={()=>{setActivModal(false)}}><AddRepas recette={recette} closeModal={()=>{setActivModal(false)}}/></Modal> :false}
        <div className='contenair contenair--column  warpper'>
            <SearchBar filter={{active:true,show:ToggleshowFilter}} text={text}  setText={handelText}/>
            {showFitre &&
             <div className='contenair contenair--centre' >
                <div>
                    <input type='checkbox' checked={showUnactive} onChange={(e)=>{setShowUnactive(e.target.checked)}} id='showUnactive'/>
                    <label htmlFor="showUnactive">Afficher les recette desactivée</label>
                </div>
                <div>
                    <input type='checkbox' checked={orderByName} onChange={(e)=>{setOrderByName(e.target.checked)}} id='orderByName'/>
                    <label htmlFor="orderByName">Ordre alphabétique</label>
                </div>
            </div>}
            {
                recettes === false ? 
                <Loading/> :
                 recettes === 404 ?
                 <div className='contenair'>
                    <h1>
                         Aucune recette trouver
                     </h1>
                </div>:
                <div className='contenair contenair--column contenair--column--centre   warpper'>
                    <div className='contenair contenair--scroll contenair--centre content__recettes contenair--grow'>
                        {recettes.recettes.map((recette)=>(<RecetteMenu actionActiv={{add:add}} key={recette.id} recette={recette}/>))}
                    </div>
                    <Pages page={page} setPage={setPage} numberOfPage={recettes.numberOfPage}/>
                </div>
            }
        </div>
    </div>
  )
}
