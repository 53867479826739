import MenuSemaineHome from '../composants/MenuSemaineHome';
import { useContext } from 'react';
import { authcontext } from '../contexte/auth/Authcontext';
import { useEffect } from 'react';
import { useState } from 'react';
import useFetchAuth from '../hook/useFetchAuth';
import getsemaine from '../function/getsemaine';
import Loading from '../composants/Loading';

const Home = () => {
    const {api,authFetch} = useContext(authcontext)
    const [load,setLoad] = useState(false)
    /* const [menu,setMenu] = useState(false) */

    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        /* const semaine = getsemaine(new Date())
        const year = new Date().getFullYear() */
        /* fetchAuth(api+'/menu/random/4','post').then((menu)=>{
            setLoad(true)
        }).catch((err)=>{
            console.error(err)
        }) */
        authFetch.post('/menu/random/4').then(()=>{
            setLoad(true)
        }).catch((err)=>{
            console.error(err)
        })

    },[api])
    if(load === false) return <Loading/>
    return (
        <div className='contenair contenair--centre contenair--tall contenair--column contenair--space-evently '>
            <MenuSemaineHome />


        </div>
    );
};

export default Home;