import React from 'react'

export default function SearchBar({text,setText,filter={active:false}}) {
  return (
    <div className='contenair contenair--centre'>
        <div className='group-form__search contenair contenair--centre'>
          <input autoFocus value={text} onChange={(e)=>{setText(e)}} type="text" />
          {filter.active && <img onClick={filter.show} className='searchBar' src='/filter.png' alt='search'/>}
          <img className='searchBar'  src="/search.png" alt="search" />
        
        </div>
    </div>
  )
}
