

export default function MessageUi({children,messageType= "info"}) {
  console.log(children)
    return (
    <div className='contenair message message__warpper contenair--centre'>
        <span className={`message message--${messageType}`}>{children}</span>
    </div>
  )
}
