import React from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import Message from '../../class/Message';
import MessageUi from '../../composants/MessageUi';
export const mesageContext = createContext()
const MesageContext = ({children}) => {
    const [Mesage, setMesage] = useState(false)
    const messageList = []
    let displayTimer = false
    
    const nextMessage = ()=>{  
        if(messageList.length === 0){
            displayTimer = false
            return setMesage(false)
        } 
        const currentMessage = messageList.shift()   
        setMesage(currentMessage)
        displayTimer = setTimeout(nextMessage,currentMessage.time)
    }
    /**
     * 
     * @param {Message} message 
     */
    const addMessage = (message)=>{
        messageList.push(message)
        if(displayTimer === false){
            nextMessage()
        }
    }


    return (
        <mesageContext.Provider value={{Mesage, setMesage,addMessage}}>
            {children}
            {Mesage && <MessageUi messageType={Mesage.type}>{Mesage.message}</MessageUi>}
        </mesageContext.Provider>

      
    );
};

export default MesageContext;