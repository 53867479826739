
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Pages/Home';


import Listecourse from './Pages/Listecourse/Listecourse';
import Authcontext from './contexte/auth/Authcontext';
import Private from './contexte/auth/Private';
import Login from './Pages/Login';
import MesageContext from './contexte/mesage/MesageContext';
import MesMenus from './Pages/MesMenu/MesMenu';
import Cuisiner from './Pages/cuisiner/Cuisiner';
import Preference from './Pages/preference/Preference';
import Recettes from './Pages/Recettes';
import CreeCompte from './Pages/CreeCompte';
import PasswordForgot from './Pages/PasswordForgot';
import ResetPassowrd from './Pages/ResetPassowrd';
import MyAccount from './Pages/MyAccount';
import Admin from './contexte/Admin';
import GereeCompte from './Pages/GereeCompte';
import HomeAdmin from './Pages/admin/HomeAdmin';
import CategoriserIgredient from './Pages/admin/CategoriserIgredient';
import Favorie from './Pages/favorie/Favorie';
import ModifyRecette from './Pages/admin/ModifyRecette';
import { useEffect } from 'react';


function App() {
  const nav  = useNavigate()
  
  return (
  <div className='App contenair contenair--space-evently'>
    <MesageContext>
      <Authcontext>
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/cree-un-compte' element={<CreeCompte/>}/>
          <Route path='/mot-de-passe-oublier' element={<PasswordForgot/>}/>
          <Route path='/reinitialiser-mot-de-passe/:token' element={<ResetPassowrd/>}/>
          <Route path='/' element= {<Private/>}>
            <Route path='/preference' element={<Preference/>}/>
            <Route path='/home' element = {<Home/>}/>
            <Route path='/liste-de-course' element={<Listecourse/>}/>
            <Route path='/Recettes' element = {<Recettes/>}/>
            <Route path='/MesMenus' element = {<MesMenus/>}/>
            <Route path='/favoris' element = {<Favorie/>}/>
            <Route path='/cuisiner/:idRecette' element = {<Cuisiner/>}/>
            <Route path='mon-compte' element={<MyAccount/>}/>
            <Route path='/admin' element={<Admin/>}>
              <Route path='/admin/manage-user' element={<GereeCompte/>}/>
              <Route path='/admin/home' element={<HomeAdmin/>}/>
              <Route path='/admin/categoriser-ingredient' element={<CategoriserIgredient/>}/>
              <Route path='/admin/modify/:idRecette' element={<ModifyRecette/>}/>
            </Route>
          </Route>
        </Routes>
      </Authcontext>
    </MesageContext>
  </div>
  );
}

export default App;
