import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import GroupForm from '../composants/form/GroupForm';
import postJson from '../function/postJson';
import { authcontext } from '../contexte/auth/Authcontext';
import FetchAuthObject from '../class/FetchAuthObject';


const Login = () => {
    const {setUser,user,setAccessToken,setAuthFecht } = useContext(authcontext)
    const location = useLocation()
    const from = location.state?.from?.pathname || '/home'
    console.log(from)
    const nav = useNavigate()
    const form = {
        titel:'Se connecter',
        save:conect,
        valid:(data)=>{
            const rexEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
            return new Promise((resolve,reject)=>{
                let err = []
                if(data.email === ""){
                    err.push({for:'email',msg:'Veillez rensegner ce champ'})
                }else if(!rexEmail.test(data.email)){
                    err.push({for:'email',msg:'Veillez rensegner un email valide'})
                }
                if(data.password === ""){
                    err.push({for:'password',msg:'Veillez rensegner ce champ'})
                }
                if(err.length > 0){
                    reject(err)
                    return
                }
                    resolve()
                    
            })
        },
        fields:[
            {
                name:'email',
                label:"E-mail",
                type:'email',
                value:""
            },
            {
                name:"password",
                label:'Mot de passe',
                type:'password',
                value:''
            }
        ],
        fieldsModify:false,
        options:[
            {
                name:'Mot de passe oublier',
                ref:'/mot-de-passe-oublier'
            },
            {
                name:'Crée un compte',
                ref:'/cree-un-compte' 
            }
        ]
        
    }
    

    useEffect(()=>{
        if(user){
            nav('/home') 
        }
    },[user,nav])

    function conect(data) {
        return new Promise((resolve,reject)=>{
            fetch('https://auht-api.edo.ovh/login',postJson(JSON.stringify(data))).then((res)=>{
            if(res.status === 200){
                return res.json()
            }else{
                throw new Error(res.status)
            }
              
        }).then((data)=>{
                setUser(data.user)
                setAccessToken(data.accessToken)
                setAuthFecht(new FetchAuthObject(data.accessToken,'https://MenuMatic-api.edo.ovh','https://auht-api.edo.ovh/token'))
                nav(from,{replace:true})
                resolve()
        }).catch((err)=>{
            console.error(err)
            switch (err.message) {
                case "400":

                    break;
                case "401":
                    reject(new Error("Mot de passe ou nom d'utilisateur incorecte"))
                    break;
                case "500":
                    reject(new Error("Une erreur est survenue"))
                    break;     
            
                default:
                    break;
            }
        })
        })
        
    }
    return (
        
        <div className='contenair contenair--centre'>
            <div className='login__warpper contenair contenair--centre'>
                <img src="logo.png" alt='logo' className='menu_logo'/>
                <GroupForm options={form.options} titel={form.titel} save={form.save} valid={form.valid} input={true} key={'loginForm'} fields={form.fields} btnText={'Se connecter'}/>
            </div>
        </div>
        
        
    );
};

export default Login;